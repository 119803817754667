.stats__info {
  margin-top: 20px;
//   display: grid;
//   grid-template-columns: repeat(auto-fill, 300px);
  column-gap: 20px;
  .rs-heading {
    padding-bottom: 0;
  }
  .rs-divider {
    margin: 10px 0px;
  }
}
.stats__info-item {
    display: flex;
    justify-content: space-between;
width: 100%;
max-width: 320px;
    .stats__info-value {
        font-weight: bold;
    }
}
.d-flex {
    display: flex;
}
.justify-content-between {
    justify-content: space-between;
}
.country-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-left: 5px;
    text-transform: capitalize;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}
.service-block {
  // margin-top: -8px;
}
.rs-pagination-group-limit {
  .rs-picker {
    width: 150px;
  }
}