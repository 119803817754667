.auth {
  display: grid;
  width: calc(100% - 40px);
  box-shadow: none !important;
  min-height: 100vh;
}
.auth {
    .auth__wrapper {
        width: 100%;
        max-width: 500px;
        box-shadow: var(--rs-drawer-shadow);
    }
    .rs-tabs-content {
        padding: 10px;
    }
    .rs-stack-item {
        width: 100%;
    }
    .auth__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 10px;
    }
}
