@import "assets/css/components/fonts";
@import "assets/css/components/var";
@import "assets/css/components/container";
@import "assets/css/components/table";
@import "assets/css/main.scss";

// @import "assets/css/components/table.dark";
.container {
    &_xl {
        max-width: 1500px;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
    }
}

.shadow-block {
    padding: 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 4px rgba(10, 15, 0, 0.2);
}

.page {
    min-height: 100vh;
    display: flex;
    width: 100%;
    background: #ecedef;
}

.content {
    width: 100%;
}

.dark {
    .page {
        background: #202020;
        color: #fff;
    }
}

.dark-theme {
    background: #202020;
}

.light-theme {
    background: #ecedef;
}

.rdt_TableRow {
    &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
    }
}