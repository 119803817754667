aside {
  min-height: 100vh;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  background-color: #f7f7fa;
}
.rs-sidenav {
  height: 100%;
}
.rs-navbar-subtle {
  border-top: 1px solid var(--rs-gray-300);

  .rs-picker {
    margin-top: 10px;
    margin-left: 10px;
  }
}
.box-block {
  margin: 20px;
  padding: 20px;
  // background: var(--rs-gray-100);
  border-radius: 4px;
  box-shadow: var(--rs-drawer-shadow);
  min-height: calc(100vh - 40px);
  .rs-table {
    table-layout: auto !important; // Для автоматического расчета ширины

    .rs-table-column-resize-spanner {
      height: 100% !important;
    }
  }

  .rs-table-cell-content {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rs-table-cell-header-content {
    white-space: nowrap;
  }
}
.rs-container {
  overflow: hidden;
  height: 100vh;
}
.rs-content {
  overflow: auto;
}

.rs-loader {
  background: rgba(0, 0, 0, 0.4);
  z-index: 1590000;
  position: relative;
}
