.page {
    .rdt_TableHeadRow {
        border-top: 1px solid #6f6f6f;
        border-bottom-color: #6f6f6f;
        font-weight: 500;
        font-size: 16px;
    }
    .rdt_TableRow {
        border-bottom-width: 0px !important;
        font-size: 16px;
        padding: 7.5px 0px;
        align-items: center;
        min-height: auto;
        max-height: 35px;
        &:hover {
            .rdt_TableCell {
                .copy-btn {
                    opacity: 1;
                }
            }
        }
    }
    .rdt_TableCell {
        height: 100%;
        .copy-btn {
            margin-left: 5px;
            color: #1f2228;
            opacity: 0;
        }
    }
    .rdt_TableBody {
        padding-top: 7.5px;
    }
}